import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { useLayoutEffect, useMemo } from "react";
import { withPreview } from "gatsby-source-prismic";
import { useTranslation } from "react-i18next";
import { useTheme } from "emotion-theming";

import Layout from "../components/layout";
import { withTrans } from "../i18n/withTrans";
import HeroImage, {
  adapter as heroAdapter,
} from "../components/Molecules/HeroImage";
import Newsletter, {
  adapter as newsletterAdapter,
} from "../components/Molecules/Newsletter";
import { adapter as seoAdapter } from "../components/seo";
import CardSmallCarousel, {
  adapter as cardSmallCarouselAdapter,
} from "../components/Organisms/CardSmallCarousel";
import CardCarousel from "../components/Organisms/CardCarousel";
import {
  cardCarouselPortraitAdapter,
  cardCarouselPodcastAdapter,
} from "../components/Organisms/CardCarousel/adapter";
import WideList, { wideListAdapter } from "../components/Organisms/WideList";
import FigureList, {
  articlesCategoryFigureListAdapter,
  splitFigureListAdapter,
} from "../components/Molecules/FigureList";
import { adConfigAdapter } from "../utils/configAdapter";

const HomepageTroisCouleurs = ({ data, location, pageContext }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    uid,
    url,
    type,
    data: {
      smartadserver_page_name,
      smartadserver_page_id,
      smartadserver_disable_masthead,
    },
  } = data?.prismicHomeTroiscouleurs || {};

  const prismicPageContent = {
    uid,
    url,
    ...data.prismicHomeTroiscouleurs.data,
  };
  const prismicLastArticlesContent = data.allPrismicArticle?.edges;

  const prismicSiteConfiguration =
    data.allPrismicSiteConfiguration?.edges?.find(
      ({ node }) => node.data.site === pageContext.website
    ).node.data;

  const adsConfig = useMemo(
    () =>
      prismicSiteConfiguration &&
      adConfigAdapter(
        {
          ...prismicSiteConfiguration,
          smartadserver_disable_masthead,
          smartadserver_page_name,
          smartadserver_page_id,
        },
        type
      ),
    [
      prismicSiteConfiguration,
      smartadserver_disable_masthead,
      smartadserver_page_id,
      smartadserver_page_name,
      type,
    ]
  );

  useLayoutEffect(() => {
    window.sas.cmd.push(function () {
      window.sas.call("onecall", {
        siteId: adsConfig.smartadserver_site_id,
        pageId: adsConfig.smartadserver_page_id,
        formats: [{ id: 96773 }, { id: 96774 }],
      });
    });
  }, [adsConfig]);

  return (
    <Layout
      seoData={seoAdapter(data.prismicHomeTroiscouleurs)}
      location={location}
      ads={adsConfig}
    >
      {prismicPageContent && (
        <HeroImage {...heroAdapter(prismicPageContent)} heightVh={90} />
      )}
      <Newsletter {...newsletterAdapter({ template: "mini" }, { t })} />

      {prismicPageContent?.articles_list?.length !== 0 && (
        <FigureList
          {...splitFigureListAdapter({
            lastArticles: prismicLastArticlesContent,
            articlesPush: prismicPageContent.articles_list,
            title: prismicPageContent.articles_title,
            label: prismicPageContent.articles_cta_label,
            link: prismicPageContent.articles_cta_link,
            id: "trois-couleurs-all-articles",
          })}
        />
      )}

      {prismicPageContent?.theatre_articles_list?.length !== 0 && (
        <WideList
          {...wideListAdapter({
            title: prismicPageContent.theatres_title,
            list: prismicPageContent.theatre_articles_list,
          })}
          animate
        />
      )}

      {prismicPageContent?.selection_articles_list?.length !== 0 && (
        <FigureList
          fixedPushEnable={true}
          {...articlesCategoryFigureListAdapter({
            title: prismicPageContent.selection_title,
            list: prismicPageContent.selection_articles_list,
            id: "trois-couleurs-selection-articles",
          })}
          ads={adsConfig}
        />
      )}

      {prismicPageContent?.portraits_carousel?.length !== 0 && (
        <CardCarousel
          {...cardCarouselPortraitAdapter(
            {
              portraitsContent: prismicPageContent,
              id: "trois-couleurs-portraits",
            },
            { theme, t }
          )}
        />
      )}

      {prismicPageContent?.culture_articles_list?.length !== 0 && (
        <FigureList
          {...articlesCategoryFigureListAdapter({
            title: prismicPageContent.culture_title,
            list: prismicPageContent.culture_articles_list,
            label: prismicPageContent.culture_cta_label,
            link: prismicPageContent.culture_cta_link,
            id: "trois-couleurs-culture-articles",
          })}
        />
      )}

      {prismicPageContent?.kids_article_list?.length !== 0 && (
        <FigureList
          {...articlesCategoryFigureListAdapter({
            title: prismicPageContent.kids_title,
            list: prismicPageContent.kids_article_list,
            label: prismicPageContent.kids_cta_label,
            link: prismicPageContent.kids_cta_link,
            id: "trois-couleurs-kids-articles",
          })}
        />
      )}

      {prismicPageContent?.podcasts_carousel?.length !== 0 && (
        <CardCarousel
          {...cardCarouselPodcastAdapter(
            {
              podcastsContent: prismicPageContent,
              id: "trois-couleurs-podcasts",
            },
            { theme }
          )}
        />
      )}

      {!!prismicPageContent?.authors_carousel?.length !== 0 && (
        <CardSmallCarousel
          {...cardSmallCarouselAdapter(
            {
              type: "AuthorDark",
              id: "author-small-carousel",
              authorContent: prismicPageContent,
            },
            { t }
          )}
        />
      )}

      {!!prismicPageContent?.magazine_carousel?.length !== 0 && (
        <CardSmallCarousel
          {...cardSmallCarouselAdapter(
            {
              type: "Magazine",
              id: "magazine-small-carousel",
              magazineContent: prismicPageContent,
            },
            { t }
          )}
        />
      )}
      <Newsletter
        {...newsletterAdapter({ template: "troiscouleurs" }, { t })}
      />
    </Layout>
  );
};

export const query = graphql`
  query homeTroisCouleursQuery {
    ...i18n
    prismicHomeTroiscouleurs {
      url
      uid
      type
      data {
        smartadserver_page_id
        smartadserver_page_name
        smartadserver_disable_masthead
        meta_title
        meta_description
        meta_image {
          ...Image
        }
        title
        background_image {
          ...Image
        }
        description {
          ...StructuredText
        }
        links {
          link_label
          link_path {
            ...Link
          }
        }
        authors_title
        authors_cta_link {
          ...Link
        }
        authors_cta_label
        authors_carousel {
          author {
            document {
              ...Author
            }
          }
        }
        magazine_cta_link {
          ...Link
        }
        magazine_carousel {
          pdf_description
          pdf_download_link {
            ...Link
          }
          pdf_title
          thumbnail_image {
            ...Image
          }
        }
        magazine_cta_label
        magazine_title
        portraits_carousel {
          article {
            document {
              ...Article
            }
          }
        }
        portraits_cta_label
        portraits_title
        portraits_cta_link {
          ...Link
        }
        podcast_cta_label
        podcast_title
        podcasts_carousel {
          article {
            document {
              ...Article
            }
          }
        }
        podcast_cta_link {
          ...Link
        }
        culture_title
        culture_cta_label
        culture_cta_link {
          ...Link
        }
        culture_articles_list {
          article {
            document {
              ...Article
            }
          }
        }
        kids_title
        kids_cta_label
        kids_cta_link {
          ...Link
        }
        kids_article_list {
          article {
            document {
              ...Article
            }
          }
        }
        selection_title
        selection_articles_list {
          article {
            document {
              ...Article
            }
          }
        }
        theatres_title
        theatre_articles_list {
          article {
            document {
              ...Article
            }
          }
        }
        articles_title
        articles_cta_label
        articles_cta_link {
          ...Link
        }
        articles_list {
          article {
            document {
              ...Article
            }
          }
        }
      }
    }
    ...InternalArticles
    ...Configuration
  }
`;

HomepageTroisCouleurs.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

HomepageTroisCouleurs.defaultProps = {
  data: {},
};

export default withPreview(withTrans(HomepageTroisCouleurs));
