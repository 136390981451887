import React from "react";
import PropTypes from "prop-types";
import { breakpoints } from "../../../../styles/theme";
import CardCarousel from "../CardCarousel";
import { cardCarouselInTheatersAdapter } from "../CardCarousel/adapter";
import CardGrid, { adapter as cardGridAdapter } from "../../Molecules/CardGrid";
import useWindowSize from "../../../hooks/useWindowSize";
import { useTheme } from "emotion-theming";

const WideList = ({ title, list, animate }) => {
  const { width } = useWindowSize();
  const theme = useTheme();

  const isMobile = width < breakpoints.l;

  return isMobile ? (
    <CardCarousel
      {...cardCarouselInTheatersAdapter(
        {
          title,
          cards: list,
          id: "trois-couleurs-in-theaters",
        },
        { theme }
      )}
    />
  ) : (
    <CardGrid
      {...cardGridAdapter({
        title,
        cards: list,
        id: "trois-couleurs-in-theaters",
      })}
      type="InTheaters"
      columns={4}
      animate={animate}
    />
  );
};

WideList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array.isRequired,
  animate: PropTypes.bool,
};

export default React.memo(WideList);
